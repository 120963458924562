import styled from 'styled-components'

// Background
import BackgroundSM from './assets/background-sm-hero-consorcio.png'
import BackgroundMD from './assets/background-md-hero-consorcio.png'
import BackgroundLG from './assets/background-lg-hero-consorcio.png'
import BackgroundXL from './assets/background-xl-hero-consorcio.png'
import BackgroundXXL from './assets/background-xxl-hero-consorcio.png'

import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  background-image: url(${BackgroundSM});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @media ${device.tablet} {
    background-image: url(${BackgroundMD});
    height: 462px;
  }
  @media ${device.desktopLG} {
    background-image: url(${BackgroundLG});
    height: 556px;
  }
  @media ${device.desktopXL} {
    background-image: url(${BackgroundXL});
    height: 711px;
  }
  @media ${device.desktopXXXL} {
    background-image: url(${BackgroundXXL});
    height: 834px;
  }

  padding-top: 40px;
  padding-bottom: 40px;

  @media ${device.desktopLG} {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  @media ${device.desktopXL} {
    padding-top: 65px;
    padding-bottom: 86px;
  }

  @media ${device.desktopXXXL} {
    padding-top: 195px;
    padding-bottom: 30px;
  }

  .btn {
    height: 48px;

    @media ${device.tablet} {
      min-width: 322px;
    }
    @media ${device.desktopLG} {
      min-width: 396px;
    }
    @media ${device.desktopXL} {
      min-width: 496px;
    }
    @media ${device.desktopXXXL} {
      min-width: 577px;
    }
  }

  .gatsby-image-wrapper {
    margin: 0 auto;
  }
`

export const ImgSize = styled.div`
  .gatsby-image-wrapper {
    float: right;
  }
`
