import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      contaDigitalPfMobile: imageSharp(fluid: {originalName: { regex: "/conta-digital-inter-pf-tablet-mobile/" }}) {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      contaDigitalPfDesck: imageSharp(fluid: {originalName: { regex: "/conta-digital-inter-pf-desktop/" }}) {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      contaDigitalPjMobile: imageSharp(fluid: {originalName: { regex: "/conta-digital-inter-pj-tablet-mobile/" }}) {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      contaDigitalPjDesck: imageSharp(fluid: {originalName: { regex: "/conta-digital-inter-pj-desktop/" }}) {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      qrCodePjInterCo: imageSharp(fluid: {originalName: { regex: "/qrCodePjInterCo/" }}) {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      qrCodePfInterCo: imageSharp(fluid: {originalName: { regex: "/qrCodePfInterCo/" }}) {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      contaEmDolarOuRealMobile: imageSharp(fluid: {originalName: { regex: "/conta-em-dolar-ou-real-mobile/" }}) {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      CartaoDeCreditoIdealMobile: imageSharp(fluid: {originalName: { regex: "/cartao-de-credito-ideal-mobile/" }}) {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      seuDinheiroSuasEscolhasMobile: imageSharp(fluid: {originalName: { regex: "/eu-dinheiro-suas-escolhas-mobile/" }}) {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      BannerHeroConsorcio: imageSharp(fluid: { originalName: { regex: "/banner-header-consorcio/" }}) {
        fluid(maxWidth: 490, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      OptionsHeroConsorcio: imageSharp(fluid: { originalName: { regex: "/options-header/" }}) {
        fluid(maxWidth: 490, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      HeroBlackFridayHome: imageSharp(fluid: { originalName: { regex: "/hero-black-friday-home/" }}) {
        fluid(maxWidth: 300, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
