import React, { useState } from 'react'
import useDomReady from 'src/hooks/window/useDomReady'
import Layout from 'src/layouts/BaseLayout'
import OpenAccountRightForm from 'src/components/UI/Forms/OpenAccountRightForm'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import { Modal } from 'src/components/Modal'
import * as URLs from 'src/config/api/Urls'

import HeroConsorcio from './sections/hero-consorcio/_index'
import SuperAppInter from './sections/super-app-inter/_index'
import BancoInter from './sections/banco-inter/_index'
import ShopInter from './sections/shop-inter/_index'
import Beneficios from './sections/beneficios/_index'
import InterInvest from './sections/inter-invest/_index'
import InterEmpresa from './sections/inter-empresas/_index'
import InterSeguros from './sections/inter-seguros/_index'
import EmprestimosFinanciamento from './sections/emprestimos-financiamento/_index'
import Cashback from './sections/cashback/_index'

import pageContext from './pageContext.json'

import { Wrapper } from './style'

const Home = () => {
  const domReady = useDomReady()
  const lang = 'pt'
  const [ isModalOpen, setIsModalOpen ] = useState(false)

  const [ dataLayer, setDataLayer ] = useState<IDataLayerParams>({
    section: 'dobra_01',
    section_name: 'Inter Day 2023',
    element_action: 'click button',
    element_name: 'Conhecer agora!',
    redirect_url: URLs.SITE_BASE_URL + '/pra-voce/cartoes/programa-de-pontos/',
  })

  const superAppModal = domReady && (
    <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} locationToRender={document.body}>
      <OpenAccountRightForm closeModal={() => setIsModalOpen(false)} dataLayer={dataLayer} />
    </Modal>
  )

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        {superAppModal}
        <HeroConsorcio />
        <SuperAppInter lang={lang} setIsModalOpen={setIsModalOpen} dataLayer={dataLayer} setDataLayer={setDataLayer} />
        <BancoInter lang={lang} />
        <ShopInter lang={lang} />
        <InterInvest lang={lang} />
        <InterEmpresa />
        <InterSeguros />
        <EmprestimosFinanciamento />
        <Beneficios lang={lang} />
        <Cashback lang={lang} />
      </Layout>
    </Wrapper>
  )
}

export default Home
