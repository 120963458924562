import React from 'react'
import Img from 'gatsby-image'

import * as S from './styles'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import usePageQuery from '../../../pageQuery'

const HeroConsorcio = () => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <S.Section>
      <div className='container h-100'>
        <div className='row h-100'>
          <div className='col-12 col-md-6 mb-4 mb-md-0 d-flex flex-column justify-content-md-between pl-lg-4 pl-xl-0'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-48 lh-xl-60 fs-xxl-48 lh-xxl-60 fw-600 font-sora text-white mb-4 pt-md-5'>A MELHOR semana do ano <span className='d-md-inline'>para realizar seu sonho </span><span className='d-md-inline text-grayscale--500'>foi estendida!</span></h2>

            <div className='mb-4 d-md-none'>
              <Img
                fluid={data.BannerHeroConsorcio.fluid}
                alt='Promocional consorcio'
              />
            </div>

            <div className='pb-4 d-md-none'>
              <Img
                fluid={data.OptionsHeroConsorcio.fluid}
                alt=''
              />
            </div>

            <a
              className='btn text-orange--extra btn-white text-none mt-5'
              href='https://intergo.app/0945355b'
              target='_blank'
              rel='noreferrer'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_01',
                  section_name: 'A MELHOR semana do ano para realizar seu sonho de carro, moto e imóvel',
                  element_action: 'click button',
                  element_name: 'Simular meu sonho',
                  redirect_url: 'https://intergo.app/0945355b',
                })
              }}
            >
              Simular meu sonho
            </a>
          </div>

        </div>
      </div>
    </S.Section>
  )
}

export default HeroConsorcio
